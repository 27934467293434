import React from "react"

import Layout from "../components/layout"
import styles from "./index.module.css"

const IndexPage = () => (
  <Layout>
    <link
      href="https://fonts.googleapis.com/css?family=Playfair+Display&display=swap"
      rel="stylesheet"
    />
    <div className={styles.registerPage}>
      <div className={styles.registerContainer}>
        <p className={styles.registerHeader}>REGISTER</p>
        <p className={styles.registerSubHeader}>
          Summer Solstice Celebration Series
        </p>
        <p className={styles.registerSubHeader}>
          <ul className={styles.registerList}>
            <li>Empowered Movement</li>
            <hr />
            <li>Yoni Steam Magic</li>
            <hr />
            <li>Embodied Cycle Wisdom</li>
          </ul>
        </p>
        <a
          href="https://paypal.me/katieatyourservice/25"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.paypalMe}
        >
          {"1 Class for $25"}
        </a>
        <br />

        <a
          href="https://paypal.me/katieatyourservice/50"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.paypalMe}
        >
          {"2 Classes for $50"}
        </a>
        <br />

        <a
          href="https://paypal.me/katieatyourservice/65"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.paypalMe}
        >
          {"All 3 for $65 ($10 off)"}
        </a>
      </div>
    </div>
  </Layout>
)

export default IndexPage
